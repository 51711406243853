.fpmain {
  width: 100vw;
  height: 100vh;
  position: relative;  
  overflow: hidden;  
}

.fpmain::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: url('../../../assets/login/login_bg_image.webp');  
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  z-index: -1;  
}

.fpmain::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.3);  
  z-index: 0;  
}

.fpmain > * {
  position: relative; 
  z-index: 1;  
}
.tuliplogo{
  width: 260px;
  margin-top: 63px;
  margin-bottom: 25px;
}
.fpbox{
  width: 440px;
  padding: 24px 39px 22px 39px;

}
.fpbox h4{
  color: #fb0f01;
}
.fpbox p{
  color: gray;
}
.input-group {
  position: relative;
  margin-bottom: 20px;
}

.input-icon {
  font-size: 24px;
  position: absolute;
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
  color: #888;
}

.input-group input {
  width: 100%;
  padding: 10px 40px;  
  border: 1px solid #ddd;
  
  font-size: 14px;
}

.input-group input:focus {
  outline: none;
  border-color: #fb0f01;  
}

.options {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.options label {
  font-size: 14px;
  color: #555;
}

.options .forgot-password {
  color: #fb0f01;
  text-decoration: none;
  font-size: 14px;
}

.options .forgot-password:hover {
  text-decoration: underline;
}

.login-btn {
  width: 100%;
  background: linear-gradient(to right,#fb0f01, #fe591d);
  color: white;
  border: none;
  padding: 10px;
  font-size: 16px;
  cursor: pointer;

}

.login-btn:hover {
  background: linear-gradient(to right,  #fc5316, #fb0f01);
}

.footer-text {
  margin-top: 32px;
  font-size: 14px;
  color: #888;
}

.footer-text a {
  color: #fb0f01;
  text-decoration: none;
}

.footer-text a:hover {
  text-decoration: underline;
}
input::placeholder {
  color: rgb(181, 180, 180);  
  opacity: 1;  
}
@media (max-width: 576px) {
  .fpbox{
    width: 390px;
    padding: 21px 35px 19px 35px;
  
  }
  .fpmain {
    width: 100vw;
    position: relative;  
    overflow: hidden;  
  }
  .tuliplogo{
    width: 230px;
    margin-top: 55px;
    margin-bottom: 25px;
  }
}
@media (max-width: 425px) {
  .fpbox{
    width: 330px;
    padding: 21px 26px 16px 26px;
  
  }
  .tuliplogo{
    width: 230px;
    margin-top: 50px;
    margin-bottom: 25px;
  }
}


.errorhh{
  height: 50px;
}
.errorhh div{
  margin: 0;
  margin-top: 17px;
}


@media (max-width:577px){
  .errorhh{
    height: 45px;
  }  
  .errorhh div{
    margin: 0;
    margin-top: 22px;
  }
}