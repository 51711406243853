.pageWrapper {
  display: flex;
  height: 100vh;
  padding-top: 60px;
}

.sidebarArea {
  height: 100%;  
  overflow-y: hidden;  
  flex-shrink: 0; 
  -ms-overflow-style: none;  
  scrollbar-width: none;  
  z-index:999;
}

.contentArea {
  display: flex;
  flex-direction: column;
  flex-grow: 1;  
  overflow-y: auto;
}

.header {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000;  
  background-color: white;
  height: 60px;
}

.wrapper {
  flex-grow: 1;  
  overflow-y: auto; 
  padding-top: 20px; 
}

