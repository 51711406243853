.loginmain {
  width: 100%; 
  min-height: 100vh;
  position: relative; 
  overflow-x: hidden; 
  overflow-y: auto; 
  box-sizing: border-box; 
}

.loginmain::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: url('../../assets/login/login_bg_image.webp'); 
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  z-index: -1; 
}

.loginmain::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.3); 
  z-index: 0; 
}

.loginmain > * {
  position: relative; 
  z-index: 1; 
}
.tuliplogo{
  width: 260px;
  margin-top: 63px;
  margin-bottom: 25px;
}
.loginbox{
  width: 440px;
  padding: 24px 39px 22px 39px;
  margin: auto;
  margin-bottom: 100px;
}
.loginbox h4{
  color: #fb0f01;
}
.loginbox p{
  color: gray;
}
.input-group {
  position: relative;
  margin-bottom: 20px;
}


.input-icon {
  font-size: 24px;
  position: absolute;
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
  color: #888;
}

.input-group input {
  width: 100%;
  padding: 10px 40px; 
  border: 1px solid #ddd;
  
  font-size: 14px;
}

.input-group input:focus {
  outline: none;
  border-color: #fb0f01; 
}

.options {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.options label {
  font-size: 14px;
  color: #555;
}

.options .forgot-password {
  color: #fb0f01;
  text-decoration: none;
  font-size: 14px;
}

.options .forgot-password:hover {
  text-decoration: underline;
}

.login-btn {
  width: 100%;
  background: linear-gradient(to right,#fb0f01, #fe591d);
  color: white;
  border: none;
  padding: 10px;
  font-size: 16px;
  cursor: pointer;

}

.login-btn:hover {
  background: linear-gradient(to right,  #fc5316, #fb0f01);
}

.footer-text {
  margin-top: 32px;
  font-size: 14px;
  color: #888;
}

.footer-text a {
  color: #fb0f01;
  text-decoration: none;
}

.footer-text a:hover {
  text-decoration: underline;
}
input::placeholder {
  color: rgb(181, 180, 180); 
  opacity: 1; 
}
@media (max-width: 576px) {
  .loginbox{
    width: 390px;
    padding: 21px 35px 19px 35px;
  
  }
  .loginmain {
    width: 100vw;
    position: relative;
    overflow: hidden;
  }
  .tuliplogo{
    width: 230px;
    margin-top: 55px;
    margin-bottom: 25px;
  }
}
@media (max-width: 425px) {
  .loginbox{
    width: 330px;
    padding: 21px 26px 16px 26px;
  
  }
  .tuliplogo{
    width: 230px;
    margin-top: 50px;
    margin-bottom: 25px;
  }
}








.main2 {  
  display: flex;
  align-items: center;
  justify-content: center;
}

.register-container2 {
  padding: 0; 
  display: flex;
  width: 100vw;
  height: 100vh;
  max-width: 100%; 
  overflow: hidden;
  background-color: #d72f2f;
}

.signup-content2 {
  display: flex;
  font-weight: 100;
  width: 100%;
}

.form-section2 {
  width: 40%;
  padding: 50px 50px 50px 120px;
  background: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
  position: relative;
}

.custom-placeholder2::placeholder{
  font-weight: 100;
  color:rgb(205, 205, 205);
}

 
.tulip-logo2 {
  width: 110px; 
  position: relative;
  bottom: 160px;
}

.form-title2 {
  margin-bottom: 1.5rem;
  font-weight: 400;
  color: rgb(111, 111, 111);
  
}

.register-form2{
  width: 390px;  
}


.required-message{
  color: red;
  font-size: 14px;
  margin-top: 0.5rem;
}

.form-button2 {
  width: 80px;  
  text-align: center;
  margin-top: rem;
}

.bg-custom2{
  background-color: #a37cfc;
  padding: 8px; 
}

.bg-custom2:hover {
  background-color: #9970fa;  
}

.btn-container2{ 
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.copyright2{
  position: relative;
  top: 170px;
  line-height: 15px;
}

.social-media2 img{
  width: 15px;
  margin-right: 5px;
}

.image-section2 {
  width: 60%;
  background-size: cover;
  background-position: right;
  position: relative;
  padding: 0;
}

.background-image2 {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position:0% 10%;
}

.overlay-text2 {
  position: absolute;
  top: 100px;
  left: 50px;
  color: white;
  text-shadow: 1px 1px 4px rgba(0, 0, 0, 0.6);
}

.overlay-text2 h3 {
  font-size: 3.5rem;
  font-weight: 500;
  line-height: 50px;
  margin: 0;
}

.overlay-text2 p {
  margin-top: 20px;
  font-size: 1.5rem;
  font-weight: 500;
}

@media (max-width: 576px) {
  
  .register-container2 { 
    padding: 5px;  
    display: flex;
    flex-direction: column; 
    overflow: hidden;
    background-color: #ffffff;
  }
  
  .signup-content2 {
    display: flex;
    font-weight: 100; 
    margin: auto; 
  }
  
  .form-section2 {
    width: 100%;
    padding: 10px 10px;
    background: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: start;
    position: relative;
    top: 40px;
  } 
 
  .tulip-logo2 {
    width: 110px;  
    position: relative;
    bottom: 40%;
  }
  
  .register-form2{ 
    width: 100%;  
  }
  
  .copyright2{
    position: relative;
    top: 80px; 
    line-height: 15px;
  }
  
  .social-media2 img{
    width: 15px;
    margin-right: 5px;
    margin-bottom: 10px;
  }
  
  .image-section2 {
     display: none;
  }
  
  .background-image2 {
    display: none;
  }
  
  .overlay-text2 {
    display: none;
  }
}


@media (min-width: 577px) and (max-width: 768px){
 
  
  .register-container2 { 
    padding: 0;  
    display: flex; 
    overflow: hidden;
    background-color: #ffffff;
  }
  
  .signup-content2 {
    display: flex;
    font-weight: 100;

  }
  
  .form-section2 {
    width: 40%;
    padding: 1px;
    margin: 76px;
    background: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: start;
    position: relative;
  }
   
   
  .tulip-logo2 {
    width: 110px; 
    position: relative;
    bottom: 22%;
  }
  
  .form-title2 {
    margin-bottom: 1.5rem;
    font-weight: 400;
    color: rgb(111, 111, 111);
    
  }
  
  .register-form2{
    width: 100%;  
  }
  
  
  .form-button2 {
    width: 80px;  
    text-align: center;
    margin-top: rem;
  }
   
  .bg-custom2:hover {
    background-color: #9970fa;  
  }
   
  .copyright2{
    position: relative;
    top: 90px;
    line-height: 15px;
  }
  
  .social-media2 img{
    width: 15px;
    margin-right: 5px;
  }
  
  .image-section2 {
    width: 52%;
    background-size: cover;
    background-position: right;
    position: relative; 
  }
  
  .background-image2 {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position:33% 10%;
  }
  
  .overlay-text2 {
    position: absolute;
    top: 90px;
    left: 15px;
    color: white;
    text-shadow: 1px 1px 4px rgba(0, 0, 0, 0.6);
  }
  
  .overlay-text2 h3 {
    font-size: 2.5rem;
    font-weight: 500;
    line-height: 43px;
    margin: 0;
  }
  
  .overlay-text2 p {
    margin-top: 20px;
    font-size: 1.3rem;
    font-weight: 500;
  }
}


@media (min-width: 769px) and (max-width: 1024px){
  
  .register-container2 { 
    padding: 0;  
    display: flex; 
    overflow: hidden;
    background-color: #ffffff;
  }
  
  .signup-content2 {
    display: flex;
    font-weight: 100;

  }
  
  .form-section2 {
    width: 40%;
    padding: 2px 2px 2px 10px;
    margin: 76px;
    background: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: start;
    position: relative;
  }
  
  
  .tulip-logo2 {
    width: 110px; 
    position: relative;
    bottom: 22%;
  }
  
  .form-title2 {
    margin-bottom: 1.5rem;
    font-weight: 400;
    color: rgb(111, 111, 111);
    
  }
  
  .register-form2{
    width: 100%;  
  }
 
  
  .form-button2 {
    width: 80px;  
    text-align: center;
    margin-top: rem;
  }
  
  
  .copyright2{
    position: relative;
    top: 90px;
    line-height: 15px;
  }
  
  .social-media2 img{
    width: 15px;
    margin-right: 5px;
  }
  
  .image-section2 {
    width: 52%;
    background-size: cover;
    background-position: right;
    position: relative; 
  }
  
  .background-image2 {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position:33% 10%;
  }

  .overlay-text2 {
    position: absolute;
    top: 90px;
    left: 15px;
    color: white;
    text-shadow: 1px 1px 4px rgba(0, 0, 0, 0.6);
  }
  
  .overlay-text2 h3 {
    font-size: 2.5rem;
    font-weight: 500;
    line-height: 43px;
    margin: 0;
  }
  
  .overlay-text2 p {
    margin-top: 20px;
    font-size: 1.3rem;
    font-weight: 500;
  }

}


@media (min-width: 1025px) and (max-width: 1440px){
   
  .register-container2 { 
    padding: 0;  
    display: flex; 
    overflow: hidden;
    background-color: #ffffff;
  }
  
  .signup-content2 {
    display: flex;
    font-weight: 100;
  }
  
  .form-section2 {
    width: 40%; 
    margin: 10px 76px;
    background: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: start;
    position: relative;
  }
 
   
  .tulip-logo2 {
    width: 110px; 
    position: relative;
    bottom: 22%;
  }
  
  .form-title2 {
    margin-bottom: 1rem;
    font-weight: 400;
    color: rgb(111, 111, 111);
    
  }
  
  .register-form2{
    width: 90%;  
  }
  
  .form-button2 {
    width: 80px;  
    text-align: center;
    margin-top: rem;
  }
   
  .copyright2{
    position: relative;
    top: 110px;
    line-height: 15px;
  }
  
  .social-media2 img{
    width: 15px;
    margin-right: 5px;
  }
  
  .image-section2 {
    width: 60%;
    background-size: cover;
    background-position: right;
    position: relative; 
  }
  
  .background-image2 {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position:33% 10%;
  }
  
  .overlay-text2 {
    position: absolute;
    top: 90px;
    left: 20px;
    color: white;
    text-shadow: 1px 1px 4px rgba(0, 0, 0, 0.6);
  }
  
  .overlay-text2 h3 {
    font-size: 3.5rem;
    font-weight: 500;
    line-height: 55px;
    margin: 0;
  }
  
  .overlay-text2 p {
    margin-top: 20px;
    font-size: 1.5rem;
    font-weight: 500;
  }
}

@media only screen and (min-width: 1441px){
 
  
  .register-container2 { 
    padding: 0;  
    display: flex; 
    overflow: hidden;
    background-color: #ffffff;
  }
  
  .signup-content2 {
    display: flex;
    font-weight: 100;

  }
  
  .form-section2 {
    width: 40%; 
    margin: 76px;
    background: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: start;
    position: relative;
  }
  
  .custom-placeholder2::placeholder{
    font-weight: 100;
    color:rgb(205, 205, 205);
  }
  
   
  .tulip-logo2 {
    width: 110px; 
    position: relative;
    bottom: 30%;
  }
  
  .form-title2 {
    margin-bottom: 1.5rem;
    font-weight: 400;
    color: rgb(111, 111, 111);
    
  }
  
  .register-form2{
    width: 80%;  
  }
   
  
  .form-button2 {
    width: 80px;  
    text-align: center;
    margin-top: rem;
  }
  
  .bg-custom2{
    background-color: #a37cfc;
    padding: 8px; 
  }
  
 
  
  .copyright2{
    position: relative;
    top: 140px;
    line-height: 12px;
  }
  
  .social-media2 img{
    width: 15px;
    margin-right: 5px;
  }
  
  .image-section2 {
    width: 60%;
    background-size: cover;
    background-position: right;
    position: relative; 
  }
  
  .background-image2 {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position:33% 10%;
  }
  
  .overlay-text2 {
    position: absolute;
    top: 90px;
    left: 30px;
    color: white;
    text-shadow: 1px 1px 4px rgba(0, 0, 0, 0.6);
  }
  
  .overlay-text2 h3 {
    font-size: 4rem;
    font-weight: 500;
    line-height: 65px;
    margin: 0;
  }
  
  .overlay-text2 p {
    margin-top: 20px;
    font-size: 1.5rem;
    font-weight: 500;
}

}