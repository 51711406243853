body,
html,
#root {

    font-family: 'Roboto', sans-serif;
}

.btn-no-radius {
    border-radius: 0 !important;
    
}

.add-btn{
  font-weight: bold;
  
}



.save-btn{
  background-color:#a37cfc ;

}


.cancel-button{
  background-color: black;

}

.btn
{
border-radius: 0px;

}

.btn-primary
{
   background-color:#fb0f01 ;

  border:none;
}

.btn-secondary
{
  background-color: #000000;
  border:none;
}

.btn-primary:focus{
  background-color:#000000;
  border:none;
}

.btn-primary:hover{
  background-color:#000000 !important;
  border:none;
}
.btn-primary:active{
 background-color:#000000 !important;
  border:none;
}

.btn:disabled, .btn.disabled
{
  background-color:#000000;
}
/* SECTION CHECKBOX  */


.section-checkboxes {
  display: flex;
  flex-wrap: wrap;
}

.section-checkboxes .form-check {
  margin-right: 1rem;
  margin-bottom: 0.5rem;
}



.section-checkboxes input[type="checkbox"] {
  width: 1.5rem;  
  height: 1.5rem;  
  margin-right: 0.5rem;  
}

 .section-checkboxes label {
  font-size: 1.25rem;  
}

@media (max-width: 576px) {
  .section-checkboxes {
    flex-direction: column;
  }

  .section-checkboxes .form-check {
    margin-right: 0;
  }
}



/* INPUT  */


.custom-select-input {
  border: 1px solid #ced4da; 
  border-radius: 4px;
  transition: border-color 0.2s ease;
}

.custom-select-input:hover {
  border-color:  #80bdff; 
}

.custom-select-input:focus {
  outline: none; 
  border-color: #80bdff;
  box-shadow:none;
}


.form-control {
  border: 1px solid #ced4da;  
  box-shadow: none;  
  transition: border-color 0.2s ease-in-out; 
}

.form-control:focus {
  border: 1px solid #ced4da;  
  box-shadow: none;  
  outline: none;  
}

.form-control:hover {
  border: 1px solid #ced4da; 
  box-shadow: none;  
}





 .custom-navbar {
  background-color: #fb0f01;
  height: 60px;  
  display: flex;
  align-items: center;
  padding: 0 15px;  
}

.custom-logo {
  width: 150px;  
}

.navbar-toggler {
  background: transparent;  
}

.nav-link {
  display: flex;
  align-items: center;
  padding: 0;
  color:#fb0f01;
}
.nav-link:hover, .nav-link:focus, .nav-link:active
{
  color:#fb0f01 !important;
}

.text-primary
{

  color:#fb0f01 !important;
}




.swal2-popup {
  border-radius: 0px !important; 
  padding: 20px;
}


.swal2-confirm {
  background-color: #fb0f01 !important; 
  color: white !important;               
  border-radius: 0px !important;         
  border: none !important;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
}


.swal2-confirm:hover {
  background-color: #000000 !important;  
  outline: none;
}

.swal2-confirm:focus {
  background-color: #fb0f01 !important; 
  outline: none;
}

.swal2-cancel {
  background-color: #ccc !important;  
  color: black !important;
  border-radius: 0px !important;      
  border: none !important;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
}


@media (max-width:333px) {
  .custom-navbar {
    background-color: #fb0f01;
    height: 60px; 
    display: flex;
    align-items: center;
    padding: 0 2px;  
  }
  .custom-logo {
    width: 135px; 
  }
}







/* Card  */

.custom-card {
  border-radius: 0px;
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
  height: 150px;
}

.custom-card:hover {
  transform: translateY(-5px);
}

.custom-card-body {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  
}
.custom-card-content
{
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
}

.custom-card-icon {
  font-size: 3rem;
}



.custom-card-title {
  /* font-size: 1.2rem; */
  font-weight: 600;
  margin-bottom: 5px;
}

.custom-card-subtitle {
  /* font-size: 0.9rem; */
  margin-bottom: 10px;
}

.custom-card-earning {
  /* font-size: 1.8rem; */
  font-weight: 700;
}

.bg-info, .bg-success, .bg-danger, .bg-warning {
  background-color: var(--bs-info); /* or the default Bootstrap colors */
}
