.floating-back-button {
    position: fixed;
    bottom: 22px;  
    left: 284px;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background: linear-gradient(145deg,#FB0F01, #ff5125,#FB0F01);  
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
    border: none;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: all 0.3s ease;
  }
  
  .floating-back-button:hover {
    background: linear-gradient(145deg, #ff5125,#FB0F01, #ff5125);  
    transform: scale(1.1);  
  }
  
  .floating-back-button svg {
    transition: transform 0.3s ease;
  }
  
  .floating-back-button:hover svg {
    transform: translateX(-5px);  
  }
  @media (max-width:991px){
    .floating-back-button {
      display: none;
    }
  
  }